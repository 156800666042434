html,
body {
  padding: 0px;
  margin: 0px;
  background-color: #f6f7ff;
}

* {
  box-sizing: border-box;
}

.column-hidden {
  display: none;
}

.rowSelected {
  background-color: #FFFFE0; 
}

.ant-form-item-explain, .ant-form-item-extra {
  font-weight: 500;
}

.am-tab-bar-bar {  
  border-top: 0PX solid #ffffff; 
  height: 56px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tab-bar-bar {
    border-top: 0PX solid #fff; 
  }
  html:not([data-scale]) .am-tab-bar-bar::before {
    height: 0PX;
  }
}

.am-tab-bar-tab {  
  height: 80px;
}

.am-tab-bar-bar .am-tab-bar-tab-title {
  font-size: 12px; 
  font-weight : bold
}

#link {
  color: #090909;
}

#link:hover {
  color: #0A7AFF;
}

.am-tab-bar-tab:hover {  
  background-color: #f6f7ff; 
  height: 80px;
}

#hoverableListItem:hover {
  background-color: #FBFBFB; 
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor;
  background: #ffffff;
}

[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 10px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

.fixed-widgets {
  position: fixed;
  right: 2px;
  bottom: 25px;
  cursor: pointer;
}

.rce-sbar-item {
  width: 100%;
  display: block !important; 
}

.rce-sbar-item__center {
  margin: 0 !important;  
}

.rce-citem:hover {
  background:#f6f7ff !important;
}

.rce-mbox-right-notch {
  fill:#f6f7ff !important ;
}

.rce-mbox.rce-mbox-right {
  background:#f6f7ff !important;
}

.rce-mbox {
  background: #ffcc22;
}

.rce-mbox-left-notch {
  fill:#ffcc22 !important ;
}

.rce-citem-body {
  border-bottom: 0px !important;
}